<template>
  <div>
    <div class="user-queries">
      <a class="btn btn-sm btn-secondary mr-1 mb-1 btn-user-query"
        v-for="el in queriesList"
        v-bind:key="el.id"
        v-on:click.prevent="deleteQueryInner(el.id)">
        {{ el.name }}
        <span aria-hidden="true"
              class="del">&times;</span>
      </a>
    </div>
    <div v-if="queriesList.length != 0">
      <a href
         v-on:click.prevent="showDescription">{{ queriesListBtnOpenerName }}</a>
    </div>
    <div class="queries-description"
         v-if="queriesDescriptionIsVisible">
      <div v-for="(el, i) in queriesList"
           v-bind:key="el.id">
        <p class="mb-1">
          Имя: {{ el.name }}
        </p>
        <div v-if="el.filters"
             class="mb-1">
          Фильтры:
          <div class="list-filters">
            <button v-for="item in el.filters"
                    v-bind:key="item.value"
                    class="btn btn-info btn-sm mr-2"
                    v-html="getFilterItemText(item)">
            </button>
          </div>
        </div>
        <div v-if="el.polygons">
          Список полигонов:
          <span v-for="(polygon, index) in el.polygons"
               v-bind:key="`polygon_${index}`">
            <a v-bind:href="generateLinkToTheMap(polygon.points)"
               target="_blank">Полигон {{ index + 1 }}</a>
            <span v-if="index != el.polygons.length - 1">, </span>
          </span>
        </div>
        <div class="pt-1 pb-1"
             v-if="i != queriesList.length - 1">
          ------------
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import deleteQuery from '@/lib/deleteQuery';
import constants from '@/assets/data/grid_constants';

export default {
  props: {
    queries: Array,
    userId: String
  },
  data() {
    return {
      queriesList: this.queries,
      queriesDescriptionIsVisible: false
    };
  },
  computed: {
    queriesListBtnOpenerName() {
      return this.queriesDescriptionIsVisible ? 'Скрыть содержимое фильтров' : 'Показать содержимое фильтров';
    }
  },
  methods: {
    deleteQueryInner(id) {
      if (this.queriesList.length == 1) {
        alert('К сожалению, если удалить последний фильтр, то доступ будет ко всей базе, что запрещено политикой безопасности');
        return;
      }
      deleteQuery({userId: this.userId, id}).then(status => {
        if (status) {
          this.queriesList = this.queriesList.filter(el => el.id != id);
        } else {
          alert('Не удалось удалить запрос')
        }
      });
    },
    showDescription() {
      this.queriesDescriptionIsVisible = !this.queriesDescriptionIsVisible;
    },
    getFilterItemText(item) {
      const name = item.name;
      const nameRU = !name ? '' : constants['favouriteApartmentsGrid'].filter(el => el.value == name)[0].title;
      const symbol = constants.mathConditions.filter(el => el.title == item.condition)[0].symbol;
      const value = item.value;
      const value2 = item.value2;

      if (name == 'discountFromPriceMarket' && value != 'NaN') {
        return `${nameRU} ${symbol} ${(value * 100)}% ${value2 ? `и ${value2 * 100}%` : ''}`;
      }

      if (value2) {
        return `${nameRU} ${symbol} ${value} и ${value2}`;
      }

      if (value == 'undefined' || value == 'NaN') {
        return `${nameRU} ${symbol}`;  
      }

      return `${nameRU} ${symbol} ${value}`;
    },
    generateLinkToTheMap(points) {
      const coords = points.map((el, index) => {
        return `${el.lng}%2C%20${el.lat}`;
      }).join('%0A');
      let url = `https://www.keene.edu/campus/maps/tool/?coordinates=${coords}`;
      return url;
    }
  }
};
</script>

<style lang="scss" scoped>
.btn-user-query {
  display: inline-flex;
  align-items: center;

  .del {
    font-size: 1.7rem;
    line-height: 1.7rem;
    margin-left: 0.5rem;
  }
}
</style>