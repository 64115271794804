import { HotTable } from '@/lib/handsontable/base_table.js';
import { user_constants } from '@/lib/handsontable/users_table_constants.js';
import Handsontable from 'handsontable';
import { apolloClient } from '@/lib/apollo';
import { UserDataMapper } from '@/lib/data_mappers/user.js';

const user = new UserDataMapper(apolloClient);

const BASE_COLUMN_WIDTH = 140;
const COUNT_VISIBLE_OBJECTS = 500;

export class UsersHotTable {
  #container = null;
  #width = null;
  #height = null;
  #language = null;
  #data = null;
  #hotInstance = null;
  #filterFields = ['firstName', 'roles', 'email', 'contactInfo'];
  #userColumns = user_constants.USERS_HOT_TABLE_COLUMNS;

  debounceFn(colIndex, event) {
    const f = Handsontable.helper.debounce((colIndex, event) => {
      const filtersPlugin = this.#hotInstance.getPlugin('filters');

      filtersPlugin.removeConditions(colIndex);
      filtersPlugin.addCondition(colIndex, 'contains', [event.target.value]);
      filtersPlugin.filter();
    }, 100);

    return f(colIndex, event);
  }
  
  addEventListeners(input, colIndex) {
    input.addEventListener('keydown', event => {
      this.debounceFn(colIndex, event);
    });
  }
  
  getInitializedElements(colIndex) {
    const div = document.createElement('div');
    const input = document.createElement('input');
    input.className = 'form-control form-control-sm';
  
    div.className = 'filter-header';
  
    this.addEventListeners(input, colIndex);
  
    div.appendChild(input);

    return div;
  }
  
  addInput(col, TH) {
    if (typeof col !== 'number') {
      return col;
    }
  
    if (this.getFilteredColumns().includes(col) && TH.childElementCount < 2) {
      TH.appendChild(this.getInitializedElements(col));
    }
  }

  fixedColumnsLeft() {
    return document.body.clientWidth > 767 ? 1 : 0;
  }

  colWidths() {
    const arr = [];

    this.#userColumns.forEach((el) => {
      const objWidth = el.width || BASE_COLUMN_WIDTH;

      arr.push(objWidth);
    });

    return arr;
  }

  getFilteredColumns() {
    let arr = [];

    this.#userColumns.forEach((el, index) => {
      let val = el.value;

      if (this.#filterFields && this.#filterFields.indexOf(val) != -1) {
        arr.push(index);
      }
    })

    return arr;
  }

  convertDataIntoHotTableFormat(list) {
    const res = [];

    list.forEach((el) => {
      const listInner = [];
      const columns = this.#userColumns;

      listInner.push('');
      
      columns.forEach(col => {
        for (const [key, val] of Object.entries(el)) {
          let v = val;
          if (key == col.value) {
            if (col.translate) {
              if (Array.isArray(v) && v) {
                v = val.map(elem => col.translations[elem]).join(', ');
              }
            }
            if (col.hasOwnProperties) {
              if (!v) {
                v = null;
                listInner.push(v);
                return;
              }
              v = val[col.field];
            }
            listInner.push(v);
          }
        }
      });

      listInner.push(el.queries);

      res.push(listInner);
    });

    return res;
  }

  hot() {
    this.#hotInstance = new Handsontable(this.#container, {
      data: this.#data,
      colHeaders: true,
      stretchH: 'all',
      height: this.#height,
      width: '100vw',
      autoWrapRow: true,
      rowHeaders: true,
      language: this.#language,
      columns: this.#userColumns,
      columnSorting: true,
      fixedColumnsLeft: this.fixedColumnsLeft(),
      colWidth: this.colWidths(),
      filters: true,
      invalidCellClassName: 'myInvalidClass',
      afterGetColHeader: (col, TH) => this.addInput(col, TH),
      beforeRefreshDimensions() { return false; },

      beforeOnCellMouseDown(event, coords) {
        if (coords.row === -1 && event.target.nodeName === 'INPUT') {
          event.stopImmediatePropagation();
          this.deselectCell();
        }
      },
      licenseKey: process.env.VUE_APP_HANDSONTABLE_LICENSE_KEY,
    });
  }

  init(args) {
    this.#container = args.container;
    this.#width = args.width;
    this.#height = args.height;
    this.#language = args.language;
    this.#data = this.convertDataIntoHotTableFormat(args.data);

    this.hot();
  }
}

const usersHotTable = new UsersHotTable();

export default usersHotTable;
