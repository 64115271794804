<template>
  <div class="main">
    <div class="handsontable-wrap no-style">
      <data-loader v-bind:isLoading="isLoading"
                   innerText="Подождите, данные загружаются"
                   v-bind:isSpinner="true" />
      <div v-if="!isLoading">
        <div class="second-head-container p-2"
             ref="secondHead">
          <div>
            <button v-on:click.prevent="openModal"
                    class="btn btn-sm btn-primary mr-2">Создать пользователя</button>
            <button v-on:click.prevent="showUserEmailInHotTable"
                    v-bind:class="showUserEmailBtnClass">{{ showUserEmailBtnText }}</button>
          </div>
        </div>
        <div ref="usersHotTable"
             class="users-table"></div>
        <p v-if="!listUsers && !isLoading" class="no-data">Данных не найдено</p>
      </div>
      <div v-if="listUsers && listUsers.length > 0">

        <user-modal ref="createUserModal"
                    v-on:userCreate="userCreate"
                    v-on:userUpdate="userUpdate"
                    v-on:restoreQueryStatus="restoreQueryStatus"
                    v-on:refreshData="refreshData"
                    v-bind:queryRes="queryRes"
                    v-bind:queryStatus="queryStatus"></user-modal>
      </div>
    </div>
  </div>
</template>

<script>
import UserModal from '@/components/users/UserModal';
import user from '@/lib/data_mappers/user.js';
import DataLoader from '@/components/DataLoader.vue';
import { registerLanguageDictionary, ruRU } from 'handsontable/i18n';
import usersHotTable from '@/lib/handsontable/users_table.js';
import { users, user_constants } from '@/lib/handsontable/users_table_constants.js';
import { EventBus } from '@/patterns/event-bus.js';
import * as constants from '@/assets/data/constants';

const HEADER_HEIGHT = 51;

registerLanguageDictionary(ruRU);

export default {
  data() {
    return {
      listUsers: [],
      queryRes: null,
      isLoading: true,
      language: ruRU.languageCode,
      queryStatus: 'default',
      userEmailIsVisible: false
    };
  },
  components: {
    UserModal,
    DataLoader,
  },
  computed: {
    tableHeight() {
      return window.innerHeight - HEADER_HEIGHT - 50;
    },
    showUserEmailBtnText() {
      return this.userEmailIsVisible ? 'Скрыть адрес почты' : 'Показать адрес почты';
    },
    showUserEmailBtnClass() {
      return [
        'btn btn-sm',
        {
          'btn-primary': !this.userEmailIsVisible,
          'btn-outline-primary': this.userEmailIsVisible
        }
      ]
    }
  },
  methods: {
    openModal() {
      this.$refs.createUserModal.openModal({
        action: 'create'
      });
      this.queryStatus = 'default';
    },
    async userCreate(u) {
      this.queryStatus = 'loading';

      user.create(u).then(res => {
        if (res) {
          this.queryRes = res.data.userCreate;
          this.queryStatus = res.data.userCreate.status ? 'success' : 'error';
        } else {
          this.queryStatus = 'error';
        }
      })
    },
    async userUpdate(u) {
      this.queryStatus = 'loading';

      user.update(u).then(res => {
        if (res) {
          if (!res) {
            this.queryStatus = 'error';
            return;
          }
          this.queryRes = res.data.userUpdate;
          this.queryStatus = res.data.userUpdate.status ? 'success' : 'error';
        } else {
          this.queryStatus = 'error';
        }
      })
    },
    restoreQueryStatus() {
      this.queryStatus = 'default';
    },
    loadUserData() {
      user.getListUsers({
        first: constants.COUNT_VISIBLE_USERS,
        fields: [],
        orders: [
          {
            field: 'email',
            direction: 'asc'
          }
        ]
      }).then((res) => {
        this.listUsers = res;
        this.isLoading = false;
        this.$store.commit('users/addUsers', res);

        this.$nextTick(() => {
          const params = {
            container: this.$refs.usersHotTable,
            width: window.outerWidth,
            height: this.tableHeight,
            language: ruRU.languageCode,
            data: res
          };

          usersHotTable.init(params);
        });
      });
    },
    refreshData() {
      this.isLoading = true;

      this.loadUserData();
    },
    showUserEmailInHotTable() {
      this.userEmailIsVisible = !this.userEmailIsVisible;
      EventBus.$emit('showUserEmailInHotTable', this.userEmailIsVisible);
      this.$store.commit('users/obscureUserEmail', this.userEmailIsVisible);
    }
  },
  async mounted() {
    this.loadUserData();
  }
};
</script>
