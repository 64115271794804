import Handsontable from 'handsontable';
import { EventBus } from '@/patterns/event-bus.js';
import obscureEmail from '@/lib/obscureEmail.js';
import obscureTelegramUserName from '@/lib/obscureTelegramUserName.js';
import store from './../../store';
import * as constants from '@/assets/data/constants.js';
import user from '@/lib/data_mappers/user.js';

export const user_constants = {
  USERS_HOT_TABLE_COLUMNS: [
    {
      title: 'Панель управления',
      width: 120,
      readOnly: true,
      renderer: function(instance, td, row, col, prop, value) {
        Handsontable.renderers.HtmlRenderer.apply(this, arguments);

        let wrapper = document.createElement('div');
        wrapper.className = 'p-1 pb-1';
        
        let btnUpdateUser = document.createElement('a');
        btnUpdateUser.className = 'btn btn-sm btn-primary';
        btnUpdateUser.innerHTML = 'Изменить';
        wrapper.appendChild(btnUpdateUser);

        Handsontable.dom.addEvent(btnUpdateUser, 'mousedown', function() {
          const objId = instance.getCell(row, 1).textContent;

          let userDataObj = store.getters['users/getUsers'].filter(el => el.id == objId);
          let userData = userDataObj.length != 0 ? userDataObj[0] : null;

          EventBus.$emit('openUserModal', {
            user: {
              id: userData.id,
              email: userData.email,
              firstName: userData.firstName,
              lastName: userData.lastName,
              queries: userData.queries,
              roles: userData.roles,
              settingCountAssignObjectsInDay: userData.brokerAssignInfo.settingCountAssignObjectsInDay,
              settingCountObjectsInWork: userData.brokerAssignInfo.settingCountObjectsInWork,
              contactTelegram: userData.contactInfo ? userData.contactInfo.telegram : null
            },
            action: 'update',
          });
        });

        td.appendChild(wrapper);
      },
    },
    {
      value: 'id',
      title: 'ID',
      type: 'numeric',
      width: 60,
      readOnly: true
    },
    {
      value: 'firstName',
      title: 'Имя',
      width: 80,
      readOnly: true
    },
    {
      value: 'lastName',
      title: 'Фамилия',
      width: 140,
      readOnly: true
    },
    {
      value: 'email',
      title: 'Email',
      width: 200,
      readOnly: true,
      renderer: function(instance, td, row, col, prop, value) {
        Handsontable.renderers.HtmlRenderer.apply(this, arguments);
        
        EventBus.$on('showUserEmailInHotTable', state => {
          td.innerHTML = value != null && state ? value : obscureEmail(value);
        });

        td.innerHTML = store.getters['users/getUserEmailVisibilityState'] && value != null ? value : obscureEmail(value);
      },
    },
    {
      value: 'roles',
      title: 'Роли',
      width: 140,
      readOnly: true,
      translations: constants.userRoles,
      translate: true
    },
    {
      value: 'queries',
      title: 'Фильтры-ограничения',
      width: 140,
      readOnly: true,
      renderer: function(instance, td, row, col, prop, value) {
        Handsontable.renderers.HtmlRenderer.apply(this, arguments);
        if (value == null) return;
        const list = Array.from(value, x => x.name);
        td.innerHTML = list.join(', ');
      },
    },
    {
      value: 'brokerAssignInfo',
      title: 'Настройки брокера',
      width: 160,
      readOnly: true,
      renderer: function(instance, td, row, col, prop, value) {
        Handsontable.renderers.HtmlRenderer.apply(this, arguments);
        if (!value.settingCountAssignObjectsInDay && !value.settingCountObjectsInWork) {
          td.innerHTML = '';
          return;
        }
        let res = '';
        if (value.settingCountAssignObjectsInDay) res += `Макс. кол-во объектов в день: ${value.settingCountAssignObjectsInDay}`
        if (value.settingCountObjectsInWork) {
          res += `</br>Макс. кол-во объектов в работе: ${value.settingCountObjectsInWork}`
        } else {
          res += `</br><span class="text-danger">!! Макс. кол-во объектов в работе не введено</span>`
        }
        td.innerHTML = res;
      },
    },
    {
      value: 'contactInfo',
      title: 'Телеграм',
      width: 160,
      readOnly: true,
      hasOwnProperties: true,
      field: 'telegram',
      renderer: function(instance, td, row, col, prop, value) {
        Handsontable.renderers.HtmlRenderer.apply(this, arguments);
        td.innerHTML = obscureTelegramUserName(value);
      },
    }
  ]
};
